import api from "../http-service";

export const getAllCategories = () => api.get(`categories`);

export const createCategory = (data) => api.post(`categories`, data);

export const getCategoryById = (id) => api.get(`categories/${id}`);

export const updateCategory = (id, data) => api.patch(`categories/${id}`, data);

export const deleteCategory = (id) => api.delete(`categories/${id}`);
