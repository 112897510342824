import api from "../http-service";

export const createSlideItem = (data) => api.post(`hero`, data);

export const getAllSlideItems = () => api.get(`hero`);

export const getSlideById = (id) => api.get(`hero/${id}`);

export const updateSlider = (id, data) => api.patch(`hero/${id}`, data);

export const deleteSliderById = (id) => api.delete(`hero/${id}`);
