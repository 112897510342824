import api from "../http-service";

export const getAllProducts = () => api.get(`products`);

export const createProduct = (data) => api.post(`products`, data);

export const getProductById = (id) => api.get(`products/${id}`);

export const updateProduct = (id, data) => api.patch(`products/${id}`, data);

export const deleteProductById = (id) => api.delete(`products/${id}`);

export const deleteAllProducts = () => api.delete(`products`);
