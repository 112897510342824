import api from "../http-service";

export const getAllCompanies = () => api.get(`companies`);

export const createCompany = (data) => api.post(`companies`, data);

export const getCompanyById = (id) => api.get(`companies/${id}`);

export const updateCompany = (id, data) => api.patch(`companies/${id}`, data);

export const deleteCompany = (id) => api.delete(`companies/${id}`);
