import api from "../http-service";

export const getAllReviews = () => api.get(`reviews`);

export const createReview = (data) => api.post(`reviews`, data);

export const getReviewById = (id) => api.get(`reviews/${id}`);

export const updateReview = (id, data) => api.patch(`reviews/${id}`, data);

export const deleteReview = (id) => api.delete(`reviews/${id}`);
