import api from "../http-service";

export const createPortfolio = (data) => api.post(`portfolio`, data);

export const getAllPortfolioImages = () => api.get(`portfolio`);

export const updatePortfolio = (id, data) => api.patch(`portfolio/${id}`, data);

export const deletePortfolioImage = (id) => api.delete(`portfolio/${id}`);

export const deleteAllPortfolioImages = () => api.delete(`portfolio`);
